import { IconRegularEdit, IconRegularEyeSlash, IconSolidPlus } from '@monorepo/common/component/Icons';
import PeriodLabel from '@monorepo/common/component/PeriodLabel';
import TimeLabel from '@monorepo/common/component/TimeLabel';
import { DAYS_OF_WEEK } from '@monorepo/common/util/Time';
import React, { ReactNode } from 'react';
import { NavLink as RouterNavLink } from 'react-router-dom';
import { Col, Container, CustomInput, Nav, NavItem, NavLink, Row } from 'reactstrap';
import ActivityLabel from '../ActivityLabel';
import DisciplineAccompanistList from './accompanist/DisciplineAccompanistList';
import DisciplineConfigDetail from './config/DisciplineConfigDetail';
import DisciplineConfigDetailEditModal from './config/DisciplineConfigDetailEditModal';
import DisciplineDetailEditModal from './DisciplineDetailEditModal';

const NewDisciplineCol = () => (
  <Col>
    <DisciplineDetailEditModal title="Nový oddíl">
      {(handleModalOpen, title) => (
        <h2 className="text-right">
          <IconSolidPlus onClick={handleModalOpen} title={title} className="action-icon text-primary" />
        </h2>
      )}
    </DisciplineDetailEditModal>
  </Col>
);
interface LabelRowProps {
  label: ReactNode;
  children: ReactNode;
}
function LabelRow({ label, children }: LabelRowProps) {
  return (
    <Row>
      <Col xs={12} sm={5} className="text-muted">
        {label}
      </Col>

      <Col xs={12} sm={7}>
        {children}
      </Col>
    </Row>
  );
}

export interface DisciplineType {
  __typename?: 'Discipline';
  id: string;
  externalId?: string | null;
  name: string;
  scheduleDay: number;
  scheduleTimeFrom: string;
  scheduleTimeTo: string;
  insuranceReceiptPurpose?: string | null;
  active: boolean;
  autoCopyShiftBirthDates: boolean;
  activity: {
    __typename?: 'Activity';
    id: string;
    name: string;
    code: string;
    vsestrannost: boolean;
  };
  lastConfigs: {
    __typename?: 'DisciplineConfigPage';
    items: Array<{
      __typename?: 'DisciplineConfig';
      id: string;
      period: {
        __typename?: 'Period';
        id: string;
        year: number;
        number: number;
      };
    }>;
  };
  accompanists: Array<{
    __typename?: 'DisciplineAccompanist';
    id: string;
    ageFrom?: number | null;
    ageTo?: number | null;
    price: number;
  }>;
}

interface DisciplineDetailViewProps {
  discipline: DisciplineType;
  urlPeriodId: string | undefined;
  url: string;
}

export default function DisciplineDetailView({ discipline, urlPeriodId, url }: DisciplineDetailViewProps) {
  const {
    activity,
    lastConfigs,
    scheduleDay,
    scheduleTimeFrom,
    scheduleTimeTo,
    insuranceReceiptPurpose,
    externalId,
    accompanists
  } = discipline;

  const periodId = lastConfigs.items.length ? urlPeriodId || lastConfigs.items[0].period.id : undefined;

  const disciplineConfig = lastConfigs.items.find(config => config.period.id === periodId);

  return (
    <>
      <Container>
        {/* header with name and New icon */}
        <Row>
          <Col sm="auto">
            <DisciplineDetailEditModal key={discipline.id} title="Změna oddílu" discipline={discipline}>
              {(handleModalOpen, title) => (
                <h2>
                  <IconRegularEdit onClick={handleModalOpen} title={title} className="action-icon text-primary" />
                  {discipline.active && discipline.name}
                  {!discipline.active && (
                    <span className="text-muted">
                      {discipline.name} <IconRegularEyeSlash title="Neaktivní" className="small" />
                    </span>
                  )}
                </h2>
              )}
            </DisciplineDetailEditModal>
          </Col>
          <NewDisciplineCol />
        </Row>

        {/* discipline info */}
        <Row className="mt-3">
          <Col xs={12} lg={6}>
            <LabelRow label="Kdy se cvičí">
              {DAYS_OF_WEEK[scheduleDay]} (<TimeLabel timeString={scheduleTimeFrom} /> -{' '}
              <TimeLabel timeString={scheduleTimeTo} />)
            </LabelRow>

            <LabelRow label="Aktivita">
              <ActivityLabel activity={activity} />
            </LabelRow>

            <LabelRow label="Všestrannost">
              <CustomInput id="vsestrannost" type="switch" disabled checked={activity.vsestrannost} />
            </LabelRow>

            <LabelRow label="Externí ID">{externalId}</LabelRow>

            <Row>
              <Col xs={12} className="text-muted">
                Účel platby v potvrzeni pro pojišťovny
              </Col>
              <Col xs={12}>{insuranceReceiptPurpose}</Col>
            </Row>

            <Row>
              <Col xs={12} className="text-muted">
                Posun dat narození při kopii na další období
              </Col>
              <Col xs={12}>
                <CustomInput
                  id="autoCopyShiftBirthDates"
                  type="switch"
                  disabled
                  checked={discipline.autoCopyShiftBirthDates}
                />
              </Col>
            </Row>
          </Col>

          {/* Accompanists module */}
          <Col xs={12} lg={6} className="mt-3 mt-lg-0">
            <DisciplineAccompanistList disciplineId={discipline.id} accompanists={accompanists} />
          </Col>
        </Row>

        {/* Tabs with discipline configs */}
        <Row>
          <Col className="mt-3">
            <Nav tabs>
              {lastConfigs.items.map(config => {
                return (
                  <NavItem key={config.period.id}>
                    <NavLink
                      tag={RouterNavLink}
                      active={config.period.id === disciplineConfig?.period.id}
                      to={`${url}/${config.period.id}`}
                    >
                      <PeriodLabel period={config.period} />
                    </NavLink>
                  </NavItem>
                );
              })}

              <DisciplineConfigDetailEditModal title="Nové nastavení oddílu" disciplineId={discipline.id}>
                {(handleModalOpen, title) => (
                  <NavItem key={-1}>
                    <NavLink active={false} onClick={handleModalOpen}>
                      <span>
                        <IconSolidPlus title={title} className="action-icon text-primary" />
                      </span>
                    </NavLink>
                  </NavItem>
                )}
              </DisciplineConfigDetailEditModal>
            </Nav>

            {disciplineConfig && <DisciplineConfigDetail disciplineConfigId={disciplineConfig.id} />}
          </Col>
        </Row>
      </Container>
    </>
  );
}
