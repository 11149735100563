import { IconSolidPrint } from '@monorepo/common/component/Icons';
import MoneyLabel from '@monorepo/common/component/MoneyLabel';
import OrderLink from '@monorepo/common/component/order/OrderLink';
import PeriodLabel from '@monorepo/common/component/PeriodLabel';
import { useShowAll } from '@monorepo/common/hook/useShowAll';
import React from 'react';
import { Button, Table } from 'reactstrap';
import DisciplineNameLink from '../../discipline/DisciplineNameLink';
import InsuranceReceiptDetailEditModal from '../../insuranceReceipt/InsuranceReceiptDetailEditModal';

interface PaymentDisciplineRowProps {
  clientId: string;
  paymentDiscipline: PaymentDiscipline;
}

function PaymentDisciplineRow({ clientId, paymentDiscipline }: PaymentDisciplineRowProps) {
  const {
    disciplineConfig,
    orderDiscipline: {
      orderTotal: { orderNumber },
      priceDiscounted
    },
    insuranceReceipt
  } = paymentDiscipline;

  const { period, discipline } = disciplineConfig;

  const insuranceReceiptNew = {
    period: disciplineConfig.period,
    paymentDiscipline,
    purpose: discipline.insuranceReceiptPurpose,
    amount: priceDiscounted
  };

  const insuranceReceiptCreatable = priceDiscounted > 0 && !insuranceReceipt;

  return (
    <tr>
      <td>
        <PeriodLabel period={period} />
      </td>
      <td>
        <DisciplineNameLink discipline={discipline} period={period} />
      </td>
      <td className="text-right">
        <OrderLink orderNumber={orderNumber}>
          <MoneyLabel amount={priceDiscounted} />
        </OrderLink>
      </td>
      <td>
        {insuranceReceiptCreatable && (
          <InsuranceReceiptDetailEditModal
            key={paymentDiscipline.id}
            title="Vytvoření potvrzení pro pojišťovnu"
            clientId={clientId}
            insuranceReceipt={insuranceReceiptNew}
          >
            {(handleModalOpen, title) => (
              <IconSolidPrint onClick={handleModalOpen} title={title} className="action-icon text-primary" />
            )}
          </InsuranceReceiptDetailEditModal>
        )}
      </td>
    </tr>
  );
}

export interface PaymentDiscipline {
  id: string;
  disciplineConfig: {
    id: string;
    discipline: {
      id: string;
      name: string;
      insuranceReceiptPurpose?: string | null;
    };
    period: {
      id: string;
      year: number;
      number: number;
    };
  };
  orderDiscipline: {
    id: string;
    priceDiscounted: number;
    orderTotal: {
      id: string;
      orderNumber: string;
    };
  };
  insuranceReceipt?: {
    id: string;
  } | null;
}

interface PaymentDisciplineForClientListProps {
  clientId: string;
  paymentsDiscipline: PaymentDiscipline[];
}

export default function PaymentDisciplineForClientList({
  clientId,
  paymentsDiscipline
}: PaymentDisciplineForClientListProps) {
  const [paymentsDisciplineLimited, visibleAll, showAll] = useShowAll(4, paymentsDiscipline);
  return (
    <div>
      <Table size="sm" striped className="mb-0">
        <thead>
          <tr>
            <th>Období</th>
            <th>Oddíl</th>
            <th className="text-right">Částka</th>
            <th>Akce</th>
          </tr>
        </thead>
        <tbody>
          {paymentsDisciplineLimited.map(pd => (
            <PaymentDisciplineRow key={pd.id} clientId={clientId} paymentDiscipline={pd} />
          ))}
        </tbody>
      </Table>

      {!visibleAll && (
        <Button block size="sm" onClick={() => showAll()} title="Zobrazit všechny záznamy" className="mt-3 btn-light">
          Zobrazit vše
        </Button>
      )}
    </div>
  );
}
