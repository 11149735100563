import { useCallback, useState } from "react";

export function useShowAll<T>(
  visibleCount: number,
  items: T[]
): [T[], boolean, () => void] {
  const [visibleAll, setVisibleAll] = useState(items.length <= visibleCount);

  if (!visibleAll) {
    items = items.slice(0, visibleCount);
  }

  const showAll = useCallback(() => {
    setVisibleAll(true);
  }, [setVisibleAll]);

  return [items, visibleAll, showAll];
}
