import EditModal, {
  BaseEditModalProps,
  SaveCompletedHandler,
  VariablesExtractor
} from '@monorepo/common/component/modal/EditModal';
import QueryWithLoader from '@monorepo/common/component/QueryWithLoader';
import { failOnMissing } from '@monorepo/common/util/Validation';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import {
  ActivitiesDocument,
  SaveDisciplineDocument,
  SaveDisciplineMutation,
  SaveDisciplineMutationVariables
} from '../../graphql-operations';
import DisciplineDetailEdit, { EditFormData, validate } from './DisciplineDetailEdit';
import { DisciplineType } from './DisciplineDetailView';

interface DisciplineDetailEditModalProps extends BaseEditModalProps {
  title: string;
  discipline?: DisciplineType;
}

const data2variables: VariablesExtractor<EditFormData, SaveDisciplineMutationVariables> = data => {
  const { id, externalId, insuranceReceiptPurpose } = data;

  return {
    id,
    externalId,
    name: failOnMissing(data, 'name'),
    scheduleDay: failOnMissing(data, 'scheduleDay'),
    scheduleTimeFrom: failOnMissing(data, 'scheduleTimeFrom'),
    scheduleTimeTo: failOnMissing(data, 'scheduleTimeTo'),
    activityId: failOnMissing(data, 'activity').id,
    insuranceReceiptPurpose,
    active: failOnMissing(data, 'active'),
    autoCopyShiftBirthDates: failOnMissing(data, 'autoCopyShiftBirthDates')
  };
};

export default function DisciplineDetailEditModal({ title, discipline, children }: DisciplineDetailEditModalProps) {
  const history = useHistory();

  const handleCompleted: SaveCompletedHandler<SaveDisciplineMutation, EditFormData> = useCallback(
    (data, apolloClient) => {
      // go to detail only when creating new item
      if (!discipline) {
        history.push('/disciplines/' + data.saveDiscipline.id);
        apolloClient.resetStore();
      }
    },
    [discipline, history]
  );

  return (
    <QueryWithLoader query={ActivitiesDocument}>
      {({ data }) => {
        return (
          <EditModal
            title={title}
            tag={DisciplineDetailEdit}
            initialData={{
              activities: data.activities,
              active: true,
              autoCopyShiftBirthDates: true,
              ...discipline
            }}
            data2variables={data2variables}
            validate={validate}
            mutation={SaveDisciplineDocument}
            onCompleted={handleCompleted}
            children={children}
          />
        );
      }}
    </QueryWithLoader>
  );
}
