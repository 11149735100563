import React, { ReactNode, useContext } from "react";
import { AuthenticationContext } from "./AuthenticationContext";

export const ROLE_ADMIN_CLIENT_DELETE = "ROLE_ADMIN_CLIENT_DELETE";

export const ROLE_ADMIN_PERSON_DELETE = "ROLE_ADMIN_PERSON_DELETE";

export const ROLE_DISCIPLINE_TRAINER = "ROLE_DISCIPLINE_TRAINER";

interface SecuredProps {
  authority: string;
  children: ReactNode;
}

export default function Secured({ authority, children }: SecuredProps) {
  const { user } = useContext(AuthenticationContext);

  // client has the authority - show children
  if (user && user.authorities.includes(authority)) {
    return <>{children}</>;
  }

  // client does not have the authority
  return null;
}
