import DateLabel from '@monorepo/common/component/DateLabel';
import { IconRegularEdit, IconSolidPlus, IconSolidPrint, IconSolidTrashAlt } from '@monorepo/common/component/Icons';
import NoData from '@monorepo/common/component/NoData';
import React from 'react';
import { Button, Card, CardBody, CardHeader, Table } from 'reactstrap';
import { getServerBasename } from '../../util/Url';
import { ClientType } from '../client/ClientDetailView';
import ClientNameLink from '../client/ClientNameLink';
import { PersonSwitch } from '../person/switch/PersonSwitchDetail';
import PersonSwitchNameLink from '../person/switch/PersonSwitchNameLink';
import LegalRepresentationDetailDeleteModal from './LegalRepresentationDetailDeleteModal';
import LegalRepresentationDetailEditModal from './LegalRepresentationDetailEditModal';
import LegalRepresentationGdprAgreementNewModal from './LegalRepresentationGdprAgreementNewModal';

interface LegalRepresentationRowsProps {
  showChild: boolean;
  showRepresentative: boolean;
  child?: ClientType;
  representative?: PersonSwitch;
  legalRepresentations: LegalRepresentationType[];
}

const LegalRepresentationRows = ({
  showChild,
  showRepresentative,
  child,
  representative,
  legalRepresentations
}: LegalRepresentationRowsProps) => {
  if (!legalRepresentations.length) {
    return null;
  }
  return (
    <>
      <thead>
        <tr>
          {showChild && <th>Dítě</th>}
          {showRepresentative && <th>Zástupce</th>}
          <th>Role</th>
          <th>GDPR</th>
          <th>Akce</th>
        </tr>
      </thead>
      <tbody>
        {legalRepresentations.map(legalRepresentation => (
          <tr key={legalRepresentation.id}>
            {showChild && (
              <td>
                <ClientNameLink client={legalRepresentation.child} />
              </td>
            )}

            {showRepresentative && (
              <td>
                <PersonSwitchNameLink person={legalRepresentation.representative} />
              </td>
            )}
            <td>{legalRepresentation.role}</td>

            <td>
              {legalRepresentation.gdprAgreements.map(gdprAgreement => (
                <div key={gdprAgreement.id}>
                  <DateLabel dateString={gdprAgreement.signDateTime} />
                  &nbsp;
                  <a
                    href={
                      getServerBasename() + `/rest/export/client/card-gdpr-legal-representation/${gdprAgreement.id}`
                    }
                    title="Tisk karty člena"
                    download
                  >
                    <IconSolidPrint />
                  </a>
                </div>
              ))}
              <div>
                <LegalRepresentationGdprAgreementNewModal
                  key={legalRepresentation.id}
                  title="Udělení GDPR souhlasu"
                  legalRepresentation={legalRepresentation}
                >
                  {(handleModalOpen, title) => (
                    <IconSolidPlus onClick={handleModalOpen} title={title} className="action-icon text-primary" />
                  )}
                </LegalRepresentationGdprAgreementNewModal>
              </div>
            </td>

            <td>
              {/*edit*/}
              <LegalRepresentationDetailEditModal
                key={`${(child || representative || {}).id}-${legalRepresentation.child.id}-${
                  legalRepresentation.representative.id
                }`}
                title="Úprava zákonného zástupce"
                legalRepresentation={legalRepresentation}
                child={child}
                representative={representative}
              >
                {(handleModalOpen, title) => (
                  <IconRegularEdit onClick={handleModalOpen} title={title} className="action-icon text-primary" />
                )}
              </LegalRepresentationDetailEditModal>

              {/*delete - available only when no foreign rows exist */}
              {legalRepresentation.gdprAgreements.length === 0 && (
                <LegalRepresentationDetailDeleteModal legalRepresentation={legalRepresentation}>
                  {(handleModalOpen, title) => (
                    <IconSolidTrashAlt onClick={handleModalOpen} title={title} className="action-icon text-danger" />
                  )}
                </LegalRepresentationDetailDeleteModal>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </>
  );
};

export interface LegalRepresentationType {
  id: string;
  role: string;
  child: {
    id: string;
    personData: {
      id: string;
      degreeBefore?: string | null;
      degreeAfter?: string | null;
      firstName: string;
      lastName: string;
    };
    clientData: {
      id: string;
      birthNumber: string;
      age: number;
    };
  };
  representative: {
    id: string;
    personData: {
      id: string;
      degreeBefore?: string | null;
      degreeAfter?: string | null;
      firstName: string;
      lastName: string;
      phone?: number | null;
      email?: string | null;
    };
    clientData?: {
      id: string;
      birthNumber: string;
      age: number;
    } | null;
  };
  gdprAgreements: {
    id: string;
    signDateTime: string;
  }[];
}

interface LegalRepresentationListProps {
  child?: ClientType;
  representative?: PersonSwitch;
  legalRepresentationsChilds: LegalRepresentationType[];
  legalRepresentationsRepresentatives: LegalRepresentationType[];
}

export default function LegalRepresentationList({
  child,
  representative,
  legalRepresentationsChilds,
  legalRepresentationsRepresentatives
}: LegalRepresentationListProps) {
  const totalRowsCount = legalRepresentationsChilds.length + legalRepresentationsRepresentatives.length;

  return (
    <Card>
      <CardHeader tag="h4" className="text-center">
        Zákonný zástupce
      </CardHeader>
      <CardBody>
        <LegalRepresentationDetailEditModal
          key={(child || representative || {}).id}
          title="Přidání vazby zákonného zástupce"
          child={child}
          representative={representative}
        >
          {(handleModalOpen, title) => (
            <Button block size="sm" onClick={handleModalOpen} title={title} className="mb-3">
              Přidat
            </Button>
          )}
        </LegalRepresentationDetailEditModal>

        {totalRowsCount > 0 && (
          <Table size="sm" striped className="mb-0">
            {/*childs*/}
            <LegalRepresentationRows
              legalRepresentations={legalRepresentationsChilds}
              showChild={true}
              showRepresentative={false}
              child={child}
              representative={representative}
            />

            {/*representatives*/}
            <LegalRepresentationRows
              legalRepresentations={legalRepresentationsRepresentatives}
              showChild={false}
              showRepresentative={true}
              child={child}
              representative={representative}
            />
          </Table>
        )}

        {totalRowsCount === 0 && <NoData text="Žádní zákonní zástupci" />}
      </CardBody>
    </Card>
  );
}
