import { IconRegularEdit, IconSolidPrint, IconSolidTrashAlt } from '@monorepo/common/component/Icons';
import MoneyLabel from '@monorepo/common/component/MoneyLabel';
import NoData from '@monorepo/common/component/NoData';
import OrderLink from '@monorepo/common/component/order/OrderLink';
import PeriodLabel from '@monorepo/common/component/PeriodLabel';
import { useShowAll } from '@monorepo/common/hook/useShowAll';
import React from 'react';
import { Button, Card, CardBody, CardHeader, Table } from 'reactstrap';
import { getServerBasename } from '../../util/Url';
import InsuranceReceiptDetailDeleteModal from './InsuranceReceiptDetailDeleteModal';
import InsuranceReceiptDetailEditModal from './InsuranceReceiptDetailEditModal';

export interface InsuranceReceipt {
  id: string;
  receiptId: string;
  created: string;
  purpose: string;
  amount: number;
  period: {
    id: string;
    year: number;
    number: number;
  };
  paymentDiscipline?: {
    id: string;
    disciplineConfig: {
      id: string;
      discipline: {
        id: string;
        name: string;
      };
      period: {
        id: string;
        year: number;
        number: number;
      };
    };
    orderDiscipline: {
      id: string;
      priceDiscounted: number;
      orderTotal: {
        id: string;
        orderNumber: string;
      };
    };
  } | null;
}

interface InsuranceReceiptListProps {
  clientId: string;
  insuranceReceipts: InsuranceReceipt[];
}

export default function InsuranceReceiptList({ clientId, insuranceReceipts }: InsuranceReceiptListProps) {
  const [insuranceReceiptsLimited, visibleAll, showAll] = useShowAll(4, insuranceReceipts);
  return (
    <Card>
      <CardHeader tag="h4" className="text-center">
        Potvrzení pro pojišťovny
      </CardHeader>
      <CardBody>
        <InsuranceReceiptDetailEditModal key={clientId} title="Nové potvrzení" clientId={clientId}>
          {(handleModalOpen, title) => (
            <Button block size="sm" onClick={handleModalOpen} title={title} className="mb-3">
              Přidat
            </Button>
          )}
        </InsuranceReceiptDetailEditModal>

        {!!insuranceReceiptsLimited.length && (
          <div>
            <Table size="sm" striped className="mb-0">
              <thead>
                <tr>
                  <th>Období</th>
                  <th className="text-right">Číslo dokladu</th>
                  <th className="text-right">Částka</th>
                  <th>Akce</th>
                </tr>
              </thead>
              <tbody>
                {insuranceReceiptsLimited.map(insuranceReceipt => (
                  <tr key={insuranceReceipt.id}>
                    <td>
                      <PeriodLabel period={insuranceReceipt.period} />
                    </td>
                    <td className="text-right">{insuranceReceipt.receiptId}</td>
                    <td className="text-right">
                      {insuranceReceipt.paymentDiscipline ? (
                        <OrderLink
                          orderNumber={insuranceReceipt.paymentDiscipline.orderDiscipline.orderTotal.orderNumber}
                        >
                          <MoneyLabel amount={insuranceReceipt.amount} />
                        </OrderLink>
                      ) : (
                        <MoneyLabel amount={insuranceReceipt.amount} />
                      )}
                    </td>
                    <td>
                      {/*edit*/}
                      <InsuranceReceiptDetailEditModal
                        title="Změna potvrzení pro pojišťovnu"
                        clientId={clientId}
                        insuranceReceipt={insuranceReceipt}
                      >
                        {(handleModalOpen, title) => (
                          <IconRegularEdit
                            onClick={handleModalOpen}
                            title={title}
                            className="action-icon text-primary"
                          />
                        )}
                      </InsuranceReceiptDetailEditModal>

                      {/*print*/}
                      <a
                        href={getServerBasename() + `/rest/export/client/insurance-receipt/${insuranceReceipt.id}`}
                        title="Tisk potvrzení"
                        download
                      >
                        <IconSolidPrint />
                      </a>

                      {/*delete*/}
                      <InsuranceReceiptDetailDeleteModal clientId={clientId} insuranceReceipt={insuranceReceipt}>
                        {(handleModalOpen, title) => (
                          <IconSolidTrashAlt
                            onClick={handleModalOpen}
                            title={title}
                            className="action-icon text-danger"
                          />
                        )}
                      </InsuranceReceiptDetailDeleteModal>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>

            {!visibleAll && (
              <Button
                block
                size="sm"
                onClick={() => showAll()}
                title="Zobrazit všechny záznamy"
                className="mt-3 btn-light"
              >
                Zobrazit vše
              </Button>
            )}
          </div>
        )}

        {!insuranceReceiptsLimited.length && <NoData text="Žádná potvrzení" />}
      </CardBody>
    </Card>
  );
}
