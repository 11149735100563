import MoneyLabel from '@monorepo/common/component/MoneyLabel';
import NoData from '@monorepo/common/component/NoData';
import OrderLink from '@monorepo/common/component/order/OrderLink';
import PeriodLabel from '@monorepo/common/component/PeriodLabel';
import TokenNameLink from '@monorepo/common/component/token/TokenNameLink';
import React from 'react';
import { Button, Card, CardBody, CardHeader, Table } from 'reactstrap';
import TokenOfferModal from '../../offer/token/TokenOfferModal';

interface PaymentTokenListProps {
  clientId: string;
  paymentsToken: {
    __typename?: 'PaymentToken';
    id: string;
    token: {
      __typename?: 'Token';
      id: string;
      name: string;
      period: { __typename?: 'Period'; id: string; year: number; number: number };
    };
    orderToken: {
      __typename?: 'OrderToken';
      id: string;
      price: number;
      orderTotal: {
        __typename?: 'OrderTotal';
        id: string;
        orderNumber: string;
      };
    };
  }[];
}

export default function PaymentTokenList({ clientId, paymentsToken }: PaymentTokenListProps) {
  return (
    <Card>
      <CardHeader tag="h4" className="text-center">
        Mimořádné známky
      </CardHeader>
      <CardBody>
        <TokenOfferModal key={clientId} title="Zaplacení známky" clientId={clientId}>
          {(handleModalOpen, title) => (
            <Button block size="sm" onClick={handleModalOpen} title={title} className="mb-3">
              Přidat
            </Button>
          )}
        </TokenOfferModal>

        {!!paymentsToken.length && (
          <Table size="sm" striped className="mb-0">
            <thead>
              <tr>
                <th>Období</th>
                <th>Známka</th>
                <th className="text-right">Částka</th>
              </tr>
            </thead>
            <tbody>
              {paymentsToken.map(paymentToken => (
                <tr key={paymentToken.id}>
                  <td>
                    <PeriodLabel period={paymentToken.token.period} />
                  </td>
                  <td>
                    <TokenNameLink token={paymentToken.token} />
                  </td>
                  <td className="text-right">
                    <OrderLink orderNumber={paymentToken.orderToken.orderTotal.orderNumber}>
                      <MoneyLabel amount={paymentToken.orderToken.price} />
                    </OrderLink>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}

        {!paymentsToken.length && <NoData text="Žádné platby" />}
      </CardBody>
    </Card>
  );
}
