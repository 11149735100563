import NoData from '@monorepo/common/component/NoData';
import React from 'react';
import { Button, Card, CardBody, CardHeader } from 'reactstrap';
import DisciplineConfigWaveOfferListModal from '../../offer/discipline/DisciplineConfigWaveOfferListModal';
import PaymentDisciplineForClientList, { PaymentDiscipline } from './PaymentDisciplineForClientList';

interface PaymentDisciplineForClientProps {
  clientId: string;
  paymentsDiscipline: PaymentDiscipline[];
}

export default function PaymentDisciplineForClient({ clientId, paymentsDiscipline }: PaymentDisciplineForClientProps) {
  return (
    <Card>
      <CardHeader tag="h4" className="text-center">
        Oddílové příspěvky
      </CardHeader>
      <CardBody>
        <DisciplineConfigWaveOfferListModal key={clientId} title="Zaplacení oddílu" clientId={clientId}>
          {(handleModalOpen, title) => (
            <Button block size="sm" onClick={handleModalOpen} title={title} className="mb-3">
              Přidat
            </Button>
          )}
        </DisciplineConfigWaveOfferListModal>

        {!!paymentsDiscipline.length && (
          <>
            <PaymentDisciplineForClientList clientId={clientId} paymentsDiscipline={paymentsDiscipline} />
          </>
        )}

        {!paymentsDiscipline.length && <NoData text="Žádné platby" />}
      </CardBody>
    </Card>
  );
}
