import { IconSolidTrashAlt } from '@monorepo/common/component/Icons';
import NoData from '@monorepo/common/component/NoData';
import PeriodLabel from '@monorepo/common/component/PeriodLabel';
import { useShowAll } from '@monorepo/common/hook/useShowAll';
import React from 'react';
import { Button, Card, CardBody, CardHeader, Table } from 'reactstrap';
import { SokolRole } from '../../graphql-operations';
import SokolRoleDetailDeleteModal from './SokolRoleDetailDeleteModal';
import SokolRoleDetailEditModal from './SokolRoleDetailEditModal';
import SokolRoleLabel from './SokolRoleLabel';

interface SokolRoleListProps {
  clientId: string;
  sokolRoles: SokolRole[];
}

export default function SokolRoleList({ clientId, sokolRoles }: SokolRoleListProps) {
  const [sokolRolesLimited, visibleAll, showAll] = useShowAll(4, sokolRoles);
  return (
    <Card>
      <CardHeader tag="h4" className="text-center">
        Role
      </CardHeader>
      <CardBody>
        <SokolRoleDetailEditModal key={clientId} title="Přidání role" clientId={clientId}>
          {(handleModalOpen, title) => (
            <Button block size="sm" onClick={handleModalOpen} title={title} className="mb-3">
              Přidat
            </Button>
          )}
        </SokolRoleDetailEditModal>

        {!!sokolRolesLimited.length && (
          <div>
            <Table size="sm" striped className="mb-0">
              <thead>
                <tr>
                  <th>Období</th>
                  <th>Role</th>
                  <th>Akce</th>
                </tr>
              </thead>
              <tbody>
                {sokolRolesLimited.map(sokolRole => (
                  <tr key={sokolRole.id}>
                    <td>
                      <PeriodLabel period={sokolRole.period} />
                    </td>
                    <td>
                      <SokolRoleLabel role={sokolRole.role} />
                    </td>
                    <td>
                      <SokolRoleDetailDeleteModal clientId={clientId} sokolRole={sokolRole}>
                        {(handleModalOpen, title) => (
                          <IconSolidTrashAlt
                            onClick={handleModalOpen}
                            title={title}
                            className="action-icon text-danger"
                          />
                        )}
                      </SokolRoleDetailDeleteModal>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>

            {!visibleAll && (
              <Button
                block
                size="sm"
                onClick={() => showAll()}
                title="Zobrazit všechny záznamy"
                className="mt-3 btn-light"
              >
                Zobrazit vše
              </Button>
            )}
          </div>
        )}

        {!sokolRolesLimited.length && <NoData text="Žádné role" />}
      </CardBody>
    </Card>
  );
}
